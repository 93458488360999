.auto-complte-search-input {
    border-radius: 0px !important;
}
ul.places-litm-el {
    position: relative;
    top: 0px;
    border-top: 0px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 18px;
    z-index: 2;
}
ul >
li.AutoAddressCompleteResultItem {
    cursor: pointer;
    padding: 2px 0px 2px 10px;
}
ul >
li.AutoAddressCompleteResultItem:first-child {
    border-top: 0px;
}
ul >
li.AutoAddressCompleteResultItem:hover, .selected {
    background-color: #dcfce7;
}