@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
    width: 100vw;
    height: 100vh;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
    width: 100vw;
    height: 100vh;
}

/* fits box padding size, y margin */
.ais-RefinementList ul {
    /* @apply flex font-normal text-sm flex-wrap gap-2 my-4; */
    @apply cursor-default;
}

/* white background box */
.ais-RefinementList ul li {
    @apply bg-white p-1;
    @apply hover:bg-gray-100;
}

/* checkbox */
.ais-RefinementList ul input {
    @apply m-2;
    @apply cursor-pointer;
    @apply w-4 h-4 text-green-600 bg-gray-100 rounded border-gray-300 focus:ring-green-500 focus:ring-2;
}

.ais-RefinementList-labelText {
    @apply cursor-pointer;
    @apply font-medium text-green-900;
    @apply select-none;
}

.ais-RefinementList-count {
    @apply font-black text-white p-0.5 bg-green-400 rounded-lg;
}

/* .ais-RefinementList-item--selected {
    @apply border-2 border-green-800;
} */

